import React from 'react';
import { ClientsBackground } from '../backgrounds/ClientsBackground';
import { Container as BaseContainer } from '../components/Container';
import styled, { useTheme } from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  text-align: center;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding-top: 80px;
  padding-bottom: 80px;
`;

export function ClientsSection({ title, description, cta }) {
  const theme = useTheme();
  return (
    <ClientsBackground>
      <Container>
        <SuperHeader>{title}</SuperHeader>
        <H3
          weight="normal"
          style={{ maxWidth: '26em', margin: '8px auto 32px' }}
        >
          {description}
        </H3>
        <CTAButton
          {...cta}
          style={{ background: theme.colorsNew.product.dark50 }}
        />
      </Container>
    </ClientsBackground>
  );
}
