import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { RoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const OutlineSquare = styled(RoundedSquare).attrs({
  size: '108px',
  rotation: '60deg',
})`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colorsNew.product.light70};
  left: -70px;
  top: calc(20% - 54px);

  @media (min-width: ${MEDIUM}) {
    left: -54px;
    top: calc(30% - 54px);
  }
`;

const DarkBigSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.dark50,
  rotation: '60deg',
  size: '490px',
})`
  position: absolute;
  opacity: 0.5;
  bottom: -250px;
  right: -400px;

  @media (min-width: ${MEDIUM}) {
    bottom: -180px;
    right: calc(10% - 250px);
  }
`;

export function ClientsBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.product.dark70}
      variant={variant}
      renderBackground={_ => (
        <>
          <OutlineSquare />
          <DarkBigSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
