import React from 'react';
import { Layout } from '../layout/Layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { MarketplaceHeroSection } from '../pageSections/marketplace/MarketplaceHeroSection';
import { MarketplaceDescriptionSection } from '../pageSections/marketplace/MarketplaceDescriptionSection';
import { MarketplaceProductsSection } from '../pageSections/marketplace/MarketplaceProductsSection';
import { ClientsSection } from '../pageSections/ClientsSection';

const sectionMapping = {
  Strapi_ComponentPageSectionsHeroSection: MarketplaceHeroSection,
  Strapi_ComponentPageSectionsMarketplaceDescriptionSection: MarketplaceDescriptionSection,
  Strapi_ComponentPageSectionsMarketplaceProductsSection: MarketplaceProductsSection,
  Strapi_ComponentPageSectionsClientsSection: ClientsSection,
};

export default ({ data }) => {
  const { seoInfo, sections } = data.strapi.marketplacePage;
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            layout="imageLeftTextRight"
            {...s}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      marketplacePage {
        seoInfo {
          pageTitle
          pageDescription
          keywords
        }
        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsHeroSection {
            id
            title
            subtitle
            description
            image {
              ...Image
            }
            primaryCTA {
              ...CTA
            }
          }
          ... on Strapi_ComponentPageSectionsMarketplaceDescriptionSection {
            id
            text
          }
          ... on Strapi_ComponentPageSectionsMarketplaceProductsSection {
            id
            title
            marketplaceProducts {
              id
              title
              subtitle
              description
              ctaText
              ctaUrl
              image {
                ...Image
              }
            }
          }
          ... on Strapi_ComponentPageSectionsClientsSection {
            id
            title
            description
            cta {
              ...CTA
            }
          }
        }
      }
    }
  }
`;
