import React from 'react';

export function ResponsiveImage({
  srcSetWebp,
  sizes,
  srcSet,
  src,
  style,
  pictureStyle,
  ...rest
}) {
  return (
    <picture style={pictureStyle}>
      <source type="image/webp" srcSet={srcSetWebp} sizes={sizes} />
      <source srcSet={srcSet} sizes={sizes} />
      <img
        sizes={sizes}
        alt=""
        srcSet={srcSet}
        src={src}
        style={{ ...style, maxWidth: '100%' }}
        {...rest}
      />
    </picture>
  );
}
