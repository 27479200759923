import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { MEDIUM } from '../../breakpoints';
import GatsbyImage from 'gatsby-image';
import { Grid } from '../../components/Grid';
import { Paragraph as BaseParagraph } from '@increasecard/typed-components';
import { CTAButton } from '../../components/CTAButton';

const Wrapper = styled.div`
  padding: 80px 0;
  text-align: center;
`;

const Title = styled.h1`
  ${({ theme }) => theme.typography.superHeader}
  line-height: 51px;
  font-weight: bold;

  @media (min-width: ${MEDIUM}) {
    max-width: 14em;
    margin: 0 auto;
  }
`;

const Product = styled(Grid)`
  align-items: center;
  grid-template-areas:
    'image image'
    'text text';

  @media (min-width: ${MEDIUM}) {
    grid-template-areas: 'image image image image image text text text';
  }
`;

const ProductImage = styled.div`
  grid-area: image;
`;

const ProductText = styled.div`
  text-align: left;
  grid-area: text;
  display: flex;
  flex-direction: column;
`;

const ProductTitle = styled.h1`
  ${({ theme }) => theme.typography.superHeader}
  font-weight: bold;
  line-height: 51px;
  margin-bottom: 16px;
`;

const ProductCTA = styled(CTAButton)`
  margin-top: 16px;
`;

const Paragraph = styled(BaseParagraph)`
  margin-bottom: 8px;
`;

export function MarketplaceProductsSection({ title, marketplaceProducts }) {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        {marketplaceProducts.map(
          ({ title, subtitle, description, image, ctaText, ctaUrl }) => (
            <Product key={title}>
              <ProductImage>
                <GatsbyImage {...image.imageSharp.childImageSharp} />
              </ProductImage>
              <ProductText>
                <ProductTitle>{title}</ProductTitle>
                <Paragraph weight="bold">{subtitle}</Paragraph>
                {description.split('\n\n').map((text, index) => (
                  <Paragraph key={index}>{text}</Paragraph>
                ))}
                <ProductCTA text={ctaText} href={ctaUrl} />
              </ProductText>
            </Product>
          )
        )}
      </Container>
    </Wrapper>
  );
}
