import React from 'react';
import { Container } from '../../components/Container';
import styled from 'styled-components';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import { MEDIUM } from '../../breakpoints';

const Wrapper = styled.div`
  padding: 112px 0;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  text-align: center;
`;

const Text = styled.h2`
  ${({ theme }) => theme.typography.H2}
  max-Width: 26em;
  margin: 0 auto;

  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.H1}
    line-height: 40px;
  }
`;

const BigSquare = styled(RoundedSquare).attrs({
  size: '496px',
  bgColor: theme => theme.colorsNew.increase.dark50,
  rotation: '-21deg',
})`
  position: absolute;
  left: calc(-40px + -496px / 2);
  bottom: calc(80px + -496px / 2);

  @media (min-width: ${MEDIUM}) {
    bottom: calc(-496px / 2);
    left: 0;
  }
`;

const SmallSquare = styled(RoundedSquare).attrs({
  size: '78px',
  bgColor: theme => theme.colorsNew.increase.light30,
  rotation: '-30deg',
})`
  position: absolute;
  top: calc(-78px / 2);
  right: 10%;
`;

const NoOverflow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
`;

export function MarketplaceDescriptionSection({ text }) {
  return (
    <BackgroundBase
      allowOverflow
      baseBackground={theme => theme.colorsNew.increase.dark}
      renderBackground={() => (
        <>
          <NoOverflow>
            <BigSquare />
          </NoOverflow>
          <SmallSquare />
        </>
      )}
    >
      <Wrapper>
        <Container>
          <Text>{text}</Text>
        </Container>
      </Wrapper>
    </BackgroundBase>
  );
}
