import React from 'react';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
// import Img from 'gatsby-image';
import { MarketplaceHeroBackground } from '../../backgrounds/MarketplaceHeroBackground';
import { Container } from '../../components/Container';
import { Grid } from '../../components/Grid';
import { MEDIUM } from '../../breakpoints';
import { ResponsiveImage } from '../../components/ResponsiveImage';

const HeroContainer = styled(Container)`
  padding-top: 36px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`;

const ImageWrapper = styled.div`
  width: 90vw;
  margin-right: auto;
  margin-top: 32px;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${MEDIUM}) {
    position: absolute;
    margin-top: 80px;
    top: -64px;
    height: calc(100% - 40px);
    bottom: 0;
    left: 40px;
    width: auto;
    display: flex;
    align-items: start;

    img {
      width: auto;
    }
  }
`;

const Title = styled.h1`
  ${({ theme }) => theme.typography.superHeader}
  line-height: 51px;
  font-weight: bold;
  margin-bottom: 24px;

  @media (min-width: ${MEDIUM}) {
    letter-spacing: -0.02em;
    font-size: 60px;
    line-height: 72px;
  }
`;

const TextContainer = styled.div`
  text-align: right;
  grid-column: span 2 / span 2;
  @media (min-width: ${MEDIUM}) {
    grid-column: 6 / span 3;
  }
`;

export function MarketplaceHeroSection({ title, subtitle, image }) {
  const imageSharp = image.childImageSharp || image.imageSharp.childImageSharp;
  return (
    <MarketplaceHeroBackground>
      <HeroContainer>
        <Grid>
          <TextContainer>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <H3
              dangerouslySetInnerHTML={{ __html: subtitle }}
              weight="normal"
              style={{ marginBottom: '48px' }}
            />
          </TextContainer>
        </Grid>
      </HeroContainer>
      <ImageWrapper>
        {imageSharp && <ResponsiveImage {...imageSharp.fluid} />}
      </ImageWrapper>
    </MarketplaceHeroBackground>
  );
}
