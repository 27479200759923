import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { SmallRoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const CrossingLine = styled.div`
  position: absolute;
  width: 80px;
  height: 1300px;
  right: 420px;
  top: -240px;
  background: ${({ theme }) => theme.colorsNew.product.regular};
  opacity: 0.05;
  transform: rotate(60deg);

  @media (min-width: ${MEDIUM}) {
    width: 160px;
    top: -240px;
  }
`;

const ProductColorSmallSquare = styled(SmallRoundedSquare).attrs(
  ({ variant }) => ({
    bgColor: theme =>
      variant === 'enterpriseAccent'
        ? theme.colorsNew.product.accent
        : theme.colorsNew.product.regular,
  })
)`
  position: absolute;
  right: 15%;
  bottom: 60vw;

  @media (min-width: ${MEDIUM}) {
    top: 240px;
    bottom: unset;
    left: 55%;
  }
`;

const ProductColorMediumSquare = styled(SmallRoundedSquare).attrs({
  size: '98px',
  bgColor: theme => theme.colorsNew.product.regular,
})`
  display: none;
  position: absolute;
  left: -24px;
  bottom: 32px;

  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const OutlineSquare = styled(SmallRoundedSquare).attrs({
  size: { sm: '278px', md: '344px' },
})`
  position: absolute;
  border: 2.5px solid ${({ theme }) => theme.colorsNew.product.light30};
  top: -32px;
  left: -80px;
  clip-path: polygon(88% -6%, -6% -6%, -6% 108%, 103% 100%);
  @media (min-width: ${MEDIUM}) {
    right: calc(15% + 176px);
    left: unset;
    top: 64px;
  }
`;

export function MarketplaceHeroBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={theme => {
        switch (variant) {
          case 'enterprise':
          case 'enterpriseAccent':
            return theme.colorsNew.gray.black;
          default:
            return theme.colorsNew.gray.white;
        }
      }}
      renderBackground={() => (
        <>
          <CrossingLine />
          <ProductColorSmallSquare />
          <ProductColorMediumSquare />
          <OutlineSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
